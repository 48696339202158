import { Box, Text, PageBlock, Stack, IconCritical } from 'braid-design-system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EmptyLogoUrl from 'src/assets/SearchNew.png';
import { TableBodyState } from 'src/components/molecules/TableBodyState/TableBodyState';
import { Table, type TableHeading } from 'src/components/organisms/Table/Table';
import { PageHeader } from 'src/components/templates/PageHeader/PageHeader';
import { JOBS_PAGE_NUMBER, MATCHES_PAGE_NUMBER } from 'src/constants/constants';
import {
  type UseGetJobItem,
  useGetJobs,
} from 'src/hooks/useGetJobs/useGetJobs';
import {
  formatDate,
  type Join,
  type PathsToStringProps,
} from 'src/types/utils';

const tableTextSize = 'xsmall';

export const Jobs = () => {
  const navigate = useNavigate();
  const currentPage = sessionStorage.getItem(JOBS_PAGE_NUMBER) || 1;
  const [pageNumber, setPageNumber] = useState(Number(currentPage));

  const {
    data: jobsData,
    isFetching: isFetchingRoles,
    isError,
  } = useGetJobs({ pageNumber });

  const rows: UseGetJobItem[] = jobsData?.jobs || [];

  const pagination = jobsData?.pagination;

  const headings: Array<
    TableHeading<Join<PathsToStringProps<UseGetJobItem>, '.'>>
  > = [
    {
      label: 'Role ID',
      key: 'roleId',
      element: (index) => (
        <Text size={tableTextSize} tone="formAccent">
          <u>{rows[index]?.roleId}</u>
        </Text>
      ),
    },
    { label: 'Ad Posted Date', key: 'adPostedAt', maxlines: 1 },
    {
      label: 'Ad Expiry Date',
      element: (index) => (
        <Box>
          <Text size={tableTextSize} maxLines={1}>
            {formatDate(rows[index]?.adExpiredAt) || 'No Ad Expiry Date'}
          </Text>
          {rows[index]?.expired && (
            <Text
              size={tableTextSize}
              tone="critical"
              icon={<IconCritical />}
              maxLines={1}
            >
              Expired
            </Text>
          )}
        </Box>
      ),
    },
    { label: 'Job Position', key: 'title' },
    {
      label: 'Total SmartHire Candidates Reviewed',
      key: 'totalMatchesCount',
    },
    {
      label: 'Total SmartHire Candidates Shortlisted',
      key: 'totalSentToHirerCandidates',
    },
    { label: 'Candidates you have Reviewed', key: 'totalReviewedCandidates' },
    { label: 'Candidates Pending your Review', key: 'totalPendingCandidates' },
  ];

  const handlePageChange = (newPageNumber: number) => {
    sessionStorage.setItem(JOBS_PAGE_NUMBER, newPageNumber.toString());
    setPageNumber(newPageNumber);
  };

  const rowActionsHandler = (index: number) => {
    sessionStorage.setItem(MATCHES_PAGE_NUMBER, '1');
    navigate(`/jobs/${rows[index].id}`);
  };

  return (
    <>
      <PageHeader>
        <Text weight="strong" size="large">
          Jobs
        </Text>
      </PageHeader>
      <PageBlock>
        <Box
          paddingY={{ mobile: 'medium', tablet: 'medium', desktop: 'medium' }}
        >
          <Stack space="small">
            <Table
              id="jobs-table"
              label="Jobs table"
              textSize={tableTextSize}
              isLoading={isFetchingRoles}
              isError={isError}
              headings={headings}
              rows={rows}
              rowClickHandler={rowActionsHandler}
              pagination={{
                isEnabled: rows.length > 0,
                totalRowCount: pagination?.totalRecords || 0,
                rowsPerPage: pagination?.pageSize || 20,
                pageNumber,
                onPageChange: handlePageChange,
              }}
              emptyState={
                <TableBodyState
                  SVGComponent={
                    <img
                      src={EmptyLogoUrl}
                      alt="No data"
                      style={{ width: '136px' }}
                    />
                  }
                  title="No matching search results"
                  subtitle={
                    <Stack space="none">
                      <Text tone="secondary" size="xsmall" align="center">
                        We couldn’t find anything that matched your search.
                      </Text>
                      <Text tone="secondary" size="xsmall" align="center">
                        Please check and enter again.
                      </Text>
                    </Stack>
                  }
                />
              }
            />
          </Stack>
        </Box>
      </PageBlock>
    </>
  );
};
